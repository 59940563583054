// users
export const REGISTER = 'REGISTER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const GET_USERLIST = 'GET_USERLIST'
export const INVITE_USER = 'INVITE_USER'
export const UNINVITE_USER = 'UNINVITE_USER'
export const PROMOTE_USER = 'PROMOTE_USER'
export const DEMOTE_USER = 'DEMOTE_USER'
export const GET_USER = 'GET_USER'

// road sections
export const POST_SHP_ZIP = 'POST_SHP_ZIP'
export const GET_ENTRIES = 'GET_ENTRIES'
export const GET_ENTRY = 'GET_ENTRY'
export const GET_ENTRYPAGE = 'GET_ENTRYPAGE'
export const POST_ENTRY = 'POST_ENTRY'
export const PUT_ENTRY = 'PUT_ENTRY'
export const DELETE_ENTRY = 'DELETE_ENTRY'

// section selection
export const SET_ENTRY_SELECTION = 'SET_ENTRY_SELECTION'
export const DOWNLOAD_ENTRY_SELECTION = 'DOWNLOAD_ENTRY_SELECTION'
