<template>
  <div class="loginForm">
    <p v-if="messageText">
      {{ errorText || messageText }}
    </p>
    <form
        ref="form"
        action="#"
        @submit.prevent
    >
      <div class="mb-4">
        <div class="flex items-center justify-center">
          <input
              id="email"
              type="text"
              autocomplete="email"
              placeholder="Email"
              v-model="emailValue"
              name="email"
              class="mt-1 w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
              @input="validateEmail"
          />
        </div>
      </div>
      <div class="mb-4">
        <div class="flex items-center justify-center">
          <input
              id="password"
              type="password"
              placeholder="Passphrase"
              autocomplete="current-password"
              name="password"
              class="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              @input="validatePassphrase"
              @keypress="validatePassphrase"
          />
        </div>
      </div>
      <button
          v-if="errorText"
          class="rounded w-72 mt-4 py-2 bg-grey-600 text-grey-800"
          disabled
      >login
      </button>
      <button
          v-else
          class="rounded w-72 mt-4 py-2 bg-blue-900 text-white"
          @click="onLogin"
      >login
      </button>
    </form>
  </div>
</template>

<script>
import {usePassphrase} from "@/lib/usePassphrase";
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {LOGIN} from "@/store/operations";
import {validatePassword} from "@/components/lib/validatePassword";

export default {
  name: "LoginForm",
  emits: ['unregistered', 'logged-in'],
  props: {
    email: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
  },
  setup(props, {emit}) {
    const {hashPassphrase} = usePassphrase()
    const store = useStore()
    const form = ref(null)
    const emailValue = ref(props.email)
    const user = computed(() => store.state.user)
    const messageText = ref(props.message || 'Please Log In')
    const errorText = ref('')

    const validateEmail = e => {
      const email = e.target.value.trim()
      errorText.value = ''
      const pass = /[^@]+@[^@]+\.[^@]+/.test(email)
      if (!pass) errorText.value = 'your email must contain both @ and .'
    }
    const validatePassphrase = e => {
      const pw = e.target.value.trim()
      const pass = validatePassword(pw)
      errorText.value = (pass ? '' : 'your passphrase must be at least 6 characters long')
    }

    const onLogin = () => {
      const {
        email,
        password,
      } = form.value

      store.dispatch(LOGIN, {
        email: email.value.trim(),
        password: hashPassphrase(password.value.trim())
      })
          .catch(({response}) => {
            const {status, data} = response

            const userShouldRegister = status === 401
            if (userShouldRegister) emit('unregistered', {
              email: email.value.trim(),
              message: 'Please set a password for your account to register.'
            })

            const userIsNotInvited = status === 403
            if (userIsNotInvited) messageText.value = data?.err ?? 'Not allowed'
          })
          .then(() => emit('logged-in'))

    }

    return {
      emailValue,
      errorText,
      form,
      onLogin,
      messageText,
      user,
      validateEmail,
      validatePassphrase,
    }
  }
}
</script>

<style scoped>

</style>
