import {
  LOGIN,
  LOGOUT,
  GET_USERLIST,
  INVITE_USER,
  UNINVITE_USER,
  PROMOTE_USER,
  DEMOTE_USER,
  GET_USER,

  GET_ENTRIES,
  GET_ENTRYPAGE,
  POST_ENTRY,
  PUT_ENTRY,
  DELETE_ENTRY,

// POST_SHP_ZIP,

// SET_ENTRY_SELECTION,
  DOWNLOAD_ENTRY_SELECTION, GET_ENTRY,
} from './operations.js'

export default {
  [DOWNLOAD_ENTRY_SELECTION]() {
    // handled in component
  },

  // road sections
  [GET_ENTRIES](state, entries=[]) {
    state.entries = Object.fromEntries(entries.map(entry => [entry.uuid, entry]))
  },
  [GET_ENTRYPAGE](state, entries=[]) {
    state.entries = Object.fromEntries(entries.map(entry => [entry.uuid, entry]))
  },
  [GET_ENTRY](state, entries) {
    const entry = entries?.[0] ?? {}
    const {uuid} = entry ?? {}
    if (uuid) state.entries[uuid] = entry
  },
  [POST_ENTRY](state, entry) {
    const {uuid} = entry
    state.entries[uuid] = entry
  },
  [PUT_ENTRY](state, {uuid, patch}) {
    Object.assign(state.entries[uuid], patch)
  },
  [DELETE_ENTRY](state, entry) {
    const {uuid} = entry
    delete state.entries[uuid]
  },

  // users
  [GET_USERLIST](state, users) {
    state.users = [...users]
  },
  [GET_USER](state, user) {
    const userIndex = state.users.findIndex(item => item.name === user.email)
    if (userIndex >= 0) state.users.splice(userIndex, 1, user)
    else state.users.push(user)
  },
  [LOGIN](state, user) {
    state.user = user ?? null
  },
  [LOGOUT](state) {
    state.user = null
  },
  [PROMOTE_USER](state, user) {
    const userIndex = state.users.findIndex(item => item.name === user.email)
    state.users[userIndex] = {...user}
  },
  [DEMOTE_USER](state, user) {
    const userIndex = state.users.findIndex(item => item.name === user.email)
    state.users[userIndex] = {...user}
  },
  [INVITE_USER](state, user) {
    const userIndex = state.users.findIndex(item => item.name === user.email)
    if (userIndex >= 0) state.users.splice(userIndex, 1, {...user})
    else state.users.push({...user})
  },
  [UNINVITE_USER](state, email) {
    const userIndex = state.users.findIndex(item => item.email === email)
    state.users.splice(userIndex, 1)
  }
}
