import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/tailwind.css'
import globalAxios from './plugins/axios.js'
import 'uikit/dist/css/uikit.min.css'
import './assets/css/leaflet.css'
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

createApp(App)
    .use(store)
    .use(router)
    .provide('$axios', globalAxios)
    .component('v-select', vSelect)
    .mount('#app')
