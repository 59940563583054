<template>
<div class="registerForm">
  <p v-if="messageText">
    {{ messageText }}
  </p>
  <form
      ref="form"
      action="#"
      @submit.prevent
  >
    <div class="mb-4">
      <div class="flex items-center justify-center">
        <input
            id="email"
            type="text"
            autocomplete="email"
            placeholder="Email"
            :value="email"
            disabled
            name="email"
            class="mt-1 w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
      </div>
    </div>
    <div class="mb-4">
      <div class="flex items-center justify-center">
        <input
            id="password"
            type="password"
            placeholder="Passphrase"
            autocomplete="current-password"
            name="password"
            class="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            @input="validatePassphrase"
            @change="validatePassphrase"
            @paste="validatePassphrase"
        />
      </div>
    </div>
    <div class="mb-4">
      <div class="flex items-center justify-center">
        <input
            id="passwordRepeated"
            type="password"
            placeholder="Passphrase repeated"
            autocomplete="repeat-password"
            name="passwordRepeated"
            class="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            @input="validatePassphrase"
            @change="validatePassphrase"
            @paste="validatePassphrase"
        />
      </div>
    </div>
    <button
        v-if="messageText"
        class="rounded w-72 mt-4 py-2 bg-grey-600 text-grey-800"
        disabled
    >set password
    </button>
    <button
        v-else
        class="rounded w-72 mt-4 py-2 bg-blue-900 text-white"
        @click="onRegister"
    >register
    </button>
  </form>
</div>
</template>

<script>
import {validatePassword} from "@/components/lib/validatePassword";
import {ref} from "vue";
import {useStore} from "vuex";
import {REGISTER} from "@/store/operations";
import {usePassphrase} from "@/lib/usePassphrase";

export default {
  name: "RegisterForm",
  emits: ['registered'],
  props: {
    email: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: ''
    },
  },
  setup (props, {emit}) {
    const messageText = ref(props.message)
    const {hashPassphrase} = usePassphrase()
    const form = ref(null)
    const isInitialMessageText = ref(props.message.length ? 2 : 0)
    const store = useStore()
    const validatePassphrase = () => {
      if (isInitialMessageText.value) return isInitialMessageText.value--

      const {
        password,
        passwordRepeated,
      } = form.value

      let pass = validatePassword(password.value.trim())
      messageText.value = pass ? '' : 'your passphrase must be at least 6 characters long'

      if (pass) {
        pass = password.value?.trim() === passwordRepeated.value?.trim()
        messageText.value = pass ? '' : 'your passphrases do not match'
      }

      return pass
    }
    const onRegister = () => {
      const {
        email,
        password
      } = form.value

      store.dispatch(REGISTER, {
        email: email.value.trim(),
        password: hashPassphrase(password.value.trim())
      })
      .then(() => emit('registered', props.email))
      .catch(({response}) => {
        const {status} = response

        const unknownUser = status === 401
        if (unknownUser) messageText.value = email.value.trim() + ' is not listed. Please contact the PI for adding your email to the userlist.'

      })
    }

    return {
      form,
      messageText,
      onRegister,
      validatePassphrase,
    }
  }
}
</script>

<style scoped>

</style>
