export function getRequest({method = 'GET', url = '', data}) {
  const request = {
    method,
    url
  }
  if (data) request.data = data
  return request
}

export function generateId(src) {
  return encodeURIComponent(src.replaceAll('/', '-'))
}

const {protocol, hostname, port} = window.location
const serverPort = !port || port === 80 ? '' : `:3080`
const getBaseUrl = () => `${protocol}//${hostname.replace('localhost', '127.0.0.1')}${serverPort}`

export const getAPIUrl = () => getBaseUrl() + '/api/v1'
