import axios from '../plugins/axios'
import {
  deleteUserRequest,
  getUserRequest,
  patchUserRequest,
  postUserRequest,
  putUserRequest
} from "./requests/userRequests.js";
import {deleteEntryRequest, getEntryRequest, postEntryRequest, putEntryRequest} from "./requests/entryRequests.js";

import {
  DELETE_ENTRY,
  DEMOTE_USER,
  GET_ENTRIES,
  GET_ENTRY,
  GET_ENTRYPAGE,
  GET_USER,
  GET_USERLIST,
  INVITE_USER,
  LOGIN,
  LOGOUT,
  POST_ENTRY,
  PROMOTE_USER,
  PUT_ENTRY,
  REGISTER,
  UNINVITE_USER,
} from './operations.js'

const actions = {

  // [POST_SHP_ZIP](context, file) {
  //   return axios(getUploadRequest(file))
  //       .then(() => context.commit(
  //           POST_ENTRY,
  //           file
  //       ))
  // },

  // [SET_ENTRY_SELECTION](context, config) {
  //
  // },

  // road sections
  [GET_ENTRIES](context) {
    return axios(getEntryRequest())
        .then(response => context.commit(
            GET_ENTRIES,
            response.data
        ))
  },
  [GET_ENTRYPAGE](context, filter) {
    return axios(postEntryRequest(null, filter))
        .then(response => context.commit(
            GET_ENTRYPAGE,
            response.data
        ))
  },
  [GET_ENTRY](context, uuid) {
    return axios(getEntryRequest(uuid))
        .then(response => context.commit(
            GET_ENTRY,
            response.data
        ))
  },
  [POST_ENTRY](context, payload) {
    return axios(postEntryRequest(payload.id, payload))
        .then(response => context.commit(
            POST_ENTRY,
            response.data
        ))
  },
  [PUT_ENTRY](context, {uuid, patch}) {
    return axios(putEntryRequest(uuid, patch))
        .then(() => context.commit(
            PUT_ENTRY,
            {uuid, patch}
        ))
  },
  [DELETE_ENTRY](context, uuid) {
    return axios(deleteEntryRequest(uuid))
        .then(() => context.commit(
            DELETE_ENTRY,
            uuid
        ))
  },

  // users
  [GET_USER](context, email) {
    return axios(getUserRequest(email))
        .then(response => context.commit(
            GET_USER,
            response.data
        ))
  },
  [REGISTER](context, credentials) {
    return axios(patchUserRequest(credentials.email, credentials))
  },
  [LOGIN](context, credentials) {
    return axios(postUserRequest(credentials.email, credentials))
        .then(response => context.commit(
            LOGIN,
            response.data
        ))
  },
  [LOGOUT](context, email) {
    return axios(putUserRequest(email, null, 'logout'))
        .then(() => context.commit(
            LOGOUT,
            null
        ))
  },
  [GET_USERLIST](context) {
    return axios(getUserRequest())
        .then(response => context.commit(
            GET_USERLIST,
            response.data
        ))
  },
  [UNINVITE_USER](context, email) {
    return axios(deleteUserRequest(email))
        .then(() => context.commit(
            UNINVITE_USER,
            email
        ))
  },
  [INVITE_USER](context, email) {
    return axios(putUserRequest(email))
        .then(response => context.commit(
            INVITE_USER,
            response.data
        ))
  },
  [PROMOTE_USER](context, user) {
    return axios(putUserRequest(user, 'promote'))
        .then(result => context.commit(
            PROMOTE_USER,
            result.data
        ))
  },
  [DEMOTE_USER](context, user) {
    return axios(putUserRequest(user, 'demote'))
        .then(result => context.commit(
            DEMOTE_USER,
            result.data
        ))
  }
}
export default actions
