import {createRouter, createWebHashHistory} from "vue-router"
import Landing from "@/views/Landing.vue"
import {state} from '../store/index.js'

const getRoute = (routes, name) => routes.reduce((match, route) => {
  if (!match) {
    if (route.name === name) match = route
    else if (route.children) match = getRoute(route.children, name)
  }
  return match
}, null)

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/app",
    name: "AppView",
    component: () => import('@/views/AppView.vue'),
    meta: {
      requireLogin: true
    },
    children: [
      {
        path: '/data',
        name: 'Table',
        component: () => import('@/views/Table.vue'),
      },
      {
        path: '/map',
        name: 'Map',
        component: () => import('@/views/Map.vue'),
      },
      {
        path: "/upload",
        name: "Upload",
        component: () => import('@/views/Upload.vue'),
        meta: {
          requireAdmin: true
        },
      },
      {
        path: '/users',
        name: 'UserPermissions',
        component: () => import('@/views/UserPermissions.vue'),
        meta: {
          requireAdmin: true
        },
      },
    ]
  },
  {
    path: '/section/:id',
    name: 'section',
    component: () => import('@/views/RoadSection.vue'),
    props: true,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  let destination
  if (to.meta.requireLogin && !state.value.user) destination = getRoute(routes, 'Landing')
  if (to.meta.requireAdmin && !state.value.user?.isAdmin) destination = getRoute(routes, 'Landing')

  if (destination?.path === to.path) next()
  else next(destination)
})
export default router;
