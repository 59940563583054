<template>
  <section class="bg-gray-50">
    <div class="max-w-screen-xl px-4 py-32 mx-auto lg:h-screen lg:items-center lg:flex">
      <div class="max-w-xl mx-auto text-center">
        <h1 class="text-3xl font-extrabold sm:text-5xl">
          MINERVA
        </h1>

        <p class="mt-4 sm:leading-relaxed sm:text-xl">
          Roman road sections. Upload, search, review, edit, visualize, download.
        </p>

        <div class="flex flex-wrap justify-center gap-4 mt-8">
          <div v-show="user">
            <router-link
                class="block w-full px-12 py-3 text-sm font-medium text-white bg-red-600 rounded shadow sm:w-auto active:bg-red-500 hover:bg-red-700 focus:outline-none focus:ring"
                :to="{name: 'AppView'}"
            >
              Get Started
            </router-link>
          </div>
          <div v-show="!user">
          <LoginForm
              v-show="showLoginFormNotRegisterForm"
              :email="email"
              @unregistered="onUnregistered"
              @logged-in="onLoggedIn"
              :mesage="loginMessage"
          ></LoginForm>
          <RegisterForm
              v-if="!showLoginFormNotRegisterForm"
              v-bind="registerAttrs"
              @registered="onRegistered"
              message="Your account is listed. Please set a password to initialize it."
          ></RegisterForm>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import LoginForm from "@/components/LoginForm";
import RegisterForm from "@/components/RegisterForm";
import {useRouter} from "vue-router";

export default {
  name: "Landing",
  components: {
    LoginForm,
    RegisterForm,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const user = computed(() => store.state.user)

    const loginMessage = ref('')
    const email = ref('')
    const emailToRegister = ref(null)
    const showLoginFormNotRegisterForm = computed(() => emailToRegister.value === null)

    const registerAttrs = ref(null)
    const onUnregistered = attrs => {
      emailToRegister.value = attrs.email
      registerAttrs.value = attrs
    }
    const onRegistered = registeredEmail => {
      emailToRegister.value = null
      email.value = registeredEmail.trim()
      loginMessage.value = 'Your account is registered. Please login'
    }
    const onLoggedIn = () => router.push('/map')

    return {
      email,
      loginMessage,
      registerAttrs,
      onLoggedIn,
      onRegistered,
      onUnregistered,
      showLoginFormNotRegisterForm,
      user,
    }
  }
}
</script>

<style scoped>

</style>
