import {
  getAPIUrl,
  getRequest,
} from "./lib/requestTypes.js";

export const listUsersRequest = () => {
  const url = getUserActionUrl()
  return getRequest({
    method: 'GET',
    url,
  })
}
export const getUserRequest = email => {
  const url = getUserActionUrl(email)
  return getRequest({
    method: 'GET',
    url,
  })
}
export const postUserRequest = (email, payload) => {
  const url = getUserActionUrl(encodeURIComponent(email))
  return getRequest({
    method: 'POST',
    url,
    data: {...payload}
  })
}
export const putUserRequest = (email, payload, path = '') => {
  let url = getUserActionUrl(encodeURIComponent(email)) + '/' + path
  return getRequest({
    method: 'PUT',
    url,
    data: {...payload}
  })
}
export const patchUserRequest = (email, payload) => {
  let url = getUserActionUrl(encodeURIComponent(email))
  return getRequest({
    method: 'PATCH',
    url,
    data: {...payload}
  })
}
export const deleteUserRequest = email => {
  const url = getUserActionUrl(encodeURIComponent(email))
  return getRequest({
    method: 'DELETE',
    url,
  })
}

function getUserActionUrl(email) {
  const baseUrl = getAPIUrl()
  return `${ baseUrl }/user${ email ? ('/' + email) : '' }`
}
