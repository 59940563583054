import {
  getRequest,
  getAPIUrl,
} from "./lib/requestTypes.js";

export const listEntriesRequest = () => {
  const url = getEntryActionUrl()
  return getRequest({
    method: 'GET',
    url,
  })
}
export const getEntryRequest = id => {
  const url = getEntryActionUrl(id)
  return getRequest({
    method: 'GET',
    url,
  })
}
export const postEntryRequest = (id, data) => {
  let url = getEntryActionUrl()
  if (id) url += '/' + id
  return getRequest({
    method: 'POST',
    url,
    data
  })
}
export const putEntryRequest = (id, data, modus) => {
  let url = getEntryActionUrl(encodeURIComponent(id))
  if (modus) url += '/' + modus
  return getRequest({
    method: 'PUT',
    url,
    data,
  })
}
export const deleteEntryRequest = id => {
  const url = getEntryActionUrl(encodeURIComponent(id))
  return getRequest({
    method: 'DELETE',
    url,
  })
}

function getEntryActionUrl(id) {
  const baseUrl = getAPIUrl()
  return `${ baseUrl }/section${ id ? ('/' + id) : '' }`
}
