import {createStore} from "vuex"
import actions from './actions.js'
import mutations from './mutations.js'
import {ref} from "vue";

export const state = ref({
  currentPage: 0,
  currentPageSize: 30,
  documents: [],
  entries: {},
  results: [],
  selection: [],
  user: null,
  userPermissions: [],
  users: [],
})

export default createStore({
  state: state.value,
  mutations,
  actions,
})
